import { Link } from '@remix-run/react';
import { cn } from '@cardo/lib';
import logo from '../../../images/logo-wide.svg';
import { useEffect, useRef, useState } from 'react';
import LayoutPaddingX from '../LayoutPaddingX';
import AdvertiserDisclosure from '../../AdvertiserDisclosure';
import CoreSearchBox from '../../search/CoreSearchBox';
import { useMegaMenuContent, useOptionalUser } from '~/lib/utils';
import { useScrollDirection } from '~/hooks/useScrollDirection';
import { MenuItemsComponent } from '@cardo/types';
import { Button } from '@cardo/ui';
import SubLink from './SubLink';
import NavItem from './NavItem';
import MobileNavItem from './MobileNavItem';

export type MainNavProps = {
  className?: string;
  hideHeaderOnScroll?: boolean;
};

export default function MainNav({
  className,
  hideHeaderOnScroll,
}: MainNavProps) {
  const megaMenu = useMegaMenuContent();
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const previousDropdown = useRef<string | null>(null);
  const animationTimeout = useRef<NodeJS.Timeout | null>(null);
  const { scrollY, isScrollingUp } = useScrollDirection();

  const user = useOptionalUser();

  const dropdownTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleCloseDelay = () => {
    if (dropdownTimeout.current) {
      clearTimeout(dropdownTimeout.current);
    }

    dropdownTimeout.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 300);
  };

  useEffect(() => {
    if (mobileNavOpen || activeDropdown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [mobileNavOpen, activeDropdown]);

  useEffect(() => {
    if (activeDropdown && !previousDropdown.current) {
      setIsAnimating(true);

      if (animationTimeout.current) {
        clearTimeout(animationTimeout.current);
      }

      animationTimeout.current = setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }

    previousDropdown.current = activeDropdown;
  }, [activeDropdown]);

  useEffect(() => {
    return () => {
      if (animationTimeout.current) {
        clearTimeout(animationTimeout.current);
      }
    };
  }, []);

  const handleAnimationEnd = () => {
    if (isAnimating) {
      setIsAnimating(false);
    }
  };

  const authenticatedAppUrl =
    typeof window !== 'undefined'
      ? window.ENV.AUTHENTICATED_APP_URL
      : process.env.AUTHENTICATED_APP_URL;

  return (
    <>
      <div onMouseLeave={handleCloseDelay}>
        <LayoutPaddingX
          className={cn(
            'relative z-50 lg:px-4 xl:px-12 ',
            !activeDropdown && 'shadow-md',
          )}
        >
          <header className="hidden lg:flex ">
            <nav
              className={cn(
                'flex w-full items-center justify-between gap-6 xl:gap-8',
                className,
              )}
            >
              <div
                className="flex flex-shrink-0 h-[35px] w-[230px] lg:w-[180px] xl:w-[230px] mb-[2px] lg:mt-2 xl:mb-2"
                onMouseEnter={handleCloseDelay}
              >
                <Link to="/">
                  <img src={logo} alt="cardonomics logo" />
                </Link>
              </div>
              <div className="flex items-center justify-between gap-4 xl:gap-7 ">
                {megaMenu?.data?.attributes?.menuItems?.map(
                  (item: MenuItemsComponent, index: number) => (
                    <NavItem
                      key={index}
                      menuItem={item}
                      setActiveDropdown={setActiveDropdown}
                      activeDropdown={activeDropdown}
                    />
                  ),
                )}
              </div>
              <div
                className="flex items-center gap-6 xl:gap-6"
                onMouseEnter={handleCloseDelay}
              >
                <div className="flex items-center gap-6">
                  <AdvertiserDisclosure className="relative hidden md:flex text-xs" />
                  <CoreSearchBox />
                </div>

                <div className="w-px h-8 bg-theme-blue-darkest" />
                <div className="">
                  {user && (
                    <Link
                      to={`${authenticatedAppUrl}`}
                      className="text-theme-blue-darkest"
                    >
                      <Button
                        primary
                        large
                        className="bg-[#4F46E5] rounded-[25px]"
                      >
                        App
                      </Button>
                    </Link>
                  )}
                  {!user && (
                    <div className="flex gap-4">
                      <Link
                        to={`${authenticatedAppUrl}/login`}
                        className="block md:hidden xl:block text-theme-blue-darkest"
                      >
                        <Button ghost large className="rounded-[25px]">
                          Log in
                        </Button>
                      </Link>
                      <Link
                        to={`${authenticatedAppUrl}/login`}
                        className="block text-theme-blue-darkest"
                      >
                        <Button
                          primary
                          large
                          className="bg-[#4F46E5] rounded-[25px]"
                        >
                          Sign up
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </nav>
          </header>
        </LayoutPaddingX>
        <div
          className={cn(
            'w-full absolute z-40 top-[99.5px] lg:top-[71.5px] xl:top-[99.5px] h-[3px] bg-gray-300',
            !activeDropdown && 'hidden',
          )}
        ></div>
        <div
          onAnimationEnd={handleAnimationEnd}
          className={cn(
            `absolute top-[100px] lg:top-[70px] xl:top-[100px] z-30 bg-white w-full 
     overflow-hidden transition-[max-height] duration-500 ease-in-out shadow-md`,
            isAnimating
              ? 'animate-slide-in'
              : activeDropdown
                ? 'max-h-[1000px] opacity-100'
                : 'max-h-0 ',
          )}
        >
          <LayoutPaddingX>
            {megaMenu?.data?.attributes?.menuItems?.map(
              (item: MenuItemsComponent) => {
                if (item.label === activeDropdown) {
                  return (
                    <div
                      key={item.id}
                      className="grid grid-cols-5 gap-5  py-10 w-full"
                    >
                      {item.menuItemLinks?.map((link, index) => (
                        <div
                          key={link.id}
                          className={cn(
                            `flex flex-col gap-5 w-full`,
                            index < item.menuItemLinks.length - 1 &&
                              'border-r-2 border-gray-300 ',
                            index === 0
                              ? 'pr-10 lg:pr-6 xl:pr-10'
                              : index === item.menuItemLinks.length - 1
                                ? 'pl-10 lg:pl-6 xl:pl-10'
                                : 'px-10 lg:px-6 xl:px-10',
                          )}
                        >
                          {link.label &&
                            (link.to ? (
                              <Link
                                to={link.to}
                                className="no-underline hover:underline hover:underline-offset-[5px] decoration-black"
                              >
                                <h4 className="text-base font-bold">
                                  {link.label}
                                </h4>
                              </Link>
                            ) : (
                              <h4 className="text-base font-bold cursor-default">
                                {link.label}
                              </h4>
                            ))}

                          {link.menuItemSubLinks && (
                            <div className="flex flex-col gap-2">
                              {link.menuItemSubLinks.map((subLink) => (
                                <SubLink
                                  key={subLink.id}
                                  to={subLink.to ?? '/'}
                                  title={subLink.label ?? ''}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              },
            )}
          </LayoutPaddingX>
        </div>
      </div>

      <header
        className={cn(
          'fixed z-[1000] flex h-auto w-full lg:hidden transition-transform duration-300',
          hideHeaderOnScroll &&
            (isScrollingUp ? 'translate-y-0' : '-translate-y-full'),
          hideHeaderOnScroll && scrollY < 65 && 'translate-y-0',
        )}
      >
        <nav className="w-full">
          <div className="flex w-full flex-col space-y-2 bg-white px-4 py-4 shadow">
            <div className="relative flex w-full items-center justify-between">
              <div>
                <Link to="/" onMouseDown={() => setMobileNavOpen(false)}>
                  <img
                    src={logo}
                    alt="cardonomics logo"
                    width={180}
                    height={30}
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <button
                  className="flex w-6 flex-col space-y-1 overflow-hidden outline-none"
                  onClick={() => setMobileNavOpen((prev) => !prev)}
                >
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'translate-x-full' : ''
                    }`}
                  ></span>
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'mx-auto rotate-45' : ''
                    }`}
                  ></span>
                  <span
                    className={`bg-theme-blue-darkest h-1 w-full  rounded transition-all duration-300 ease-in-out ${
                      mobileNavOpen ? 'mx-auto -translate-y-2 -rotate-45' : ''
                    }`}
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${
              mobileNavOpen
                ? 'max-h-screen overflow-auto shadow-md'
                : 'h-0 overflow-hidden'
            } transition-[height] z-40 bg-white duration-300 ease-in-out `}
          >
            <ul
              role="menu"
              className={cn(
                'mb-24 flex flex-col p-5 overflow-auto h-full',
                className,
              )}
            >
              <CoreSearchBox className="mb-3" />
              {megaMenu?.data?.attributes?.menuItems?.map(
                (item: MenuItemsComponent, index: number) => (
                  <li key={index}>
                    <MobileNavItem menuItem={item} />
                  </li>
                ),
              )}
              <AdvertiserDisclosure className="mt-4 text-lg font-bold" />
            </ul>
          </div>
        </nav>
      </header>
      <div className="h-[57px] lg:hidden"></div>
    </>
  );
}
