import { cn } from '@cardo/lib';

import background from '../../images/background-no-ellipses.webp';
import purpleEllipse from '../../images/purple-ellipse.webp';
import blueEllipse from '../../images/blue-ellipse.svg';
import MainNav, { MainNavProps } from './MainNav';
import LayoutPaddingX from './LayoutPaddingX';
import MainFooter from './MainFooter';

interface LayoutProps {
  children: React.ReactNode;
  hero?: React.ReactNode;
  heroImage?: string;
  className?: string;
  heroContainerClassName?: string;
  smallAccents?: boolean;
  breadCrumbs?: React.ReactNode;
  mainNavProps?: MainNavProps;
  hideHeaderOnScroll?: boolean;
  wrapMainInLayoutPadding?: React.ReactNode;
}

export function LayoutPadding({ children, className }: LayoutProps) {
  return <div className={cn('p-8 sm:p-14', className)}>{children}</div>;
}

export const topRightEllipseClassNamesWithHero =
  '-right-32 md:right-0 -top-16 md:top-0';
export const topRightEllipseClassNamesNoHero =
  '-right-32 md:-right-16 -top-16 md:-top-40';
export const bottomLeftEllipseClassNamesWithHero =
  '-left-32 md:left-0 -bottom-8 md:bottom-0';
export const bottomLeftEllipseClassNamesNoHero =
  '-left-32 md:-left-16 -bottom-8 md:-bottom-12';

export default function Layout({
  children,
  hero,
  heroImage,
  className,
  heroContainerClassName,
  smallAccents,
  breadCrumbs,
  hideHeaderOnScroll,
  wrapMainInLayoutPadding = true,
}: LayoutProps) {
  return (
    <div className={cn('relative min-h-screen w-screen', className)}>
      <MainNav hideHeaderOnScroll={hideHeaderOnScroll} />
      {breadCrumbs}
      <div
        className={cn(
          'flex flex-grow flex-col',
          {
            'h-[90vh] md:h-[85vh]': !!hero && !heroContainerClassName,
            'h-[170px] md:h-[160px]': !hero && !heroContainerClassName,
            relative: !heroContainerClassName?.includes('absolute'),
          },
          heroContainerClassName,
        )}
      >
        <div className="absolute left-0 right-0 top-0 z-[-1] h-full w-full overflow-hidden">
          {!heroImage && (
            <>
              <img
                src={background}
                alt="background"
                className="h-full w-full object-cover"
              />
              <img
                src={blueEllipse}
                alt="purple ellipse"
                className={cn('absolute', {
                  [topRightEllipseClassNamesWithHero]: !!hero,
                  [topRightEllipseClassNamesNoHero]: !hero || smallAccents,
                })}
              />
              <img
                src={purpleEllipse}
                alt="purple ellipse"
                className={cn('absolute', {
                  [bottomLeftEllipseClassNamesWithHero]: !!hero,
                  [bottomLeftEllipseClassNamesNoHero]: !hero || smallAccents,
                })}
              />
            </>
          )}
          {heroImage && (
            <img
              src={heroImage}
              alt="cover"
              className="h-full w-full object-cover"
            />
          )}
        </div>
        <LayoutPaddingX className="h-full overflow-hidden">
          <section className="flex h-full flex-grow items-center justify-center">
            {hero}
          </section>
        </LayoutPaddingX>
      </div>
      {wrapMainInLayoutPadding ? (
        <LayoutPadding>
          <main>{children}</main>
        </LayoutPadding>
      ) : (
        <main>{children}</main>
      )}
      <MainFooter />
    </div>
  );
}
