import { cn } from '@cardo/lib';

type NavGroupProps = {
  title: string;
  children: React.ReactNode;
  titleClassName?: string;
};

export default function NavGroup({
  title,
  children,
  titleClassName,
}: NavGroupProps) {
  return (
    <div className="flex flex-col space-y-2">
      <div
        className={cn(
          'text-theme-blue-darkest text-lg border-b-2 border-b-theme-blue-darkest',
          titleClassName,
        )}
      >
        {title}
      </div>
      <div className="flex flex-col space-y-1.5">{children}</div>
    </div>
  );
}
