import {
  MenuItemLinksComponent,
  MenuItemsComponent,
  MenuItemSubLinksComponent,
} from '@cardo/types';
import { Link } from '@remix-run/react';
import { useState } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';
import SubLink from './SubLink';

type MobileNavItemDropdownProps = {
  menuItem: MenuItemsComponent;
};

export default function MobileNavItem({
  menuItem,
}: MobileNavItemDropdownProps) {
  const [openMenuItemLinks, setOpenMenuItemLinks] = useState<number[]>([]);

  const toggleMenuItem = (id: number) => {
    setOpenMenuItemLinks((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <div className="relative flex flex-col py-3">
      <div className="flex items-center">
        <Link
          to={menuItem.to ?? '/'}
          className="no-underline font-semibold text-lg flex items-center text-theme-blue-darkest"
        >
          <span>{menuItem.label ?? ''}</span>
        </Link>
        {menuItem.menuItemLinks && menuItem.menuItemLinks.length > 0 && (
          <IoChevronDownSharp
            size={20}
            className={`ml-2 mt-0.5 cursor-pointer transition-transform duration-300 ${
              openMenuItemLinks.includes(menuItem.id) ? 'rotate-180' : ''
            }`}
            onClick={() => toggleMenuItem(menuItem.id)}
          />
        )}
      </div>
      {menuItem.menuItemLinks && menuItem.menuItemLinks.length > 0 && (
        <div
          className={`flex flex-col px-5 gap-2 overflow-hidden transition-all duration-300 ease-in-out ${
            openMenuItemLinks.includes(menuItem.id)
              ? 'max-h-screen opacity-100'
              : 'max-h-0 opacity-0'
          }`}
        >
          {menuItem.menuItemLinks.map(
            (item: MenuItemLinksComponent, index: number) => (
              <div key={index} className="relative flex flex-col pt-3">
                <Link
                  to={item.to ?? '/'}
                  className="no-underline font-semibold text-base flex items-center text-theme-blue-darkest"
                >
                  <span>{item.label ?? ''}</span>
                </Link>
                {item.menuItemSubLinks && item.menuItemSubLinks.length > 0 && (
                  <div className="flex flex-col pt-2 gap-1.5">
                    {item.menuItemSubLinks.map(
                      (subItem: MenuItemSubLinksComponent, index: number) => (
                        <SubLink
                          key={index}
                          to={subItem.to ?? '/'}
                          title={subItem.label ?? ''}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
