import { Link } from '@remix-run/react';

type SubLinkProps = {
  to: string;
  title: string;
};

export default function SubLink({ to, title }: SubLinkProps) {
  return (
    <Link
      to={to}
      className="text-theme-blue-darkest no-underline text-sm hover:underline hover:underline-offset-[5px]"
      prefetch="intent"
    >
      {title}
    </Link>
  );
}
