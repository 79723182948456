import { cn } from '@cardo/lib';
import { MenuItemsComponent } from '@cardo/types';
import { Link } from '@remix-run/react';
import { useRef } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';

type NavItemDropdownProps = {
  setActiveDropdown: React.Dispatch<React.SetStateAction<string | null>>;
  activeDropdown: string | null;
  menuItem: MenuItemsComponent;
};

export default function NavItem({
  menuItem,
  setActiveDropdown,
  activeDropdown,
}: NavItemDropdownProps) {
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (menuItem.menuItemLinks && menuItem.menuItemLinks.length > 0) {
      const shouldDelay =
        (activeDropdown === null && menuItem.label !== null) ||
        (activeDropdown !== null && menuItem.label === null);

      if (shouldDelay) {
        hoverTimeout.current = setTimeout(() => {
          setActiveDropdown(menuItem.label);
        }, 300);
      } else {
        setActiveDropdown(menuItem.label);
      }
    } else {
      hoverTimeout.current = setTimeout(() => {
        setActiveDropdown(null);
      }, 300);
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        if (hoverTimeout.current) {
          clearTimeout(hoverTimeout.current);
        }
      }}
      className={cn(
        'flex items-center w-full group z-40 py-7 lg:py-4 xl:py-7 border-b-2 border-transparent',
        activeDropdown === menuItem.label && 'border-[rgba(0,118,255,1)]'
      )}
    >
      <Link
        to={menuItem.to ?? '/'}
        className="cursor-pointer peer no-underline text-theme-blue-darkest text-lg lg:text-base xl:text-lg"
        prefetch="intent"
        onClick={() => setActiveDropdown(null)}
      >
        <div className="group peer flex items-center py-2 font-semibold">
          <span className="whitespace-nowrap">{menuItem.label}</span>
        </div>
      </Link>
      {menuItem.menuItemLinks && menuItem.menuItemLinks.length > 0 && (
        <IoChevronDownSharp
          onClick={() => {
            if (activeDropdown === menuItem.label) {
              setActiveDropdown(null);
            } else {
              setActiveDropdown(menuItem.label);
            }
          }}
          className={cn(
            `ml-2 text-2xl cursor-pointer transition-transform`,
            activeDropdown === menuItem.label && 'rotate-180'
          )}
        />
      )}
    </div>
  );
}
